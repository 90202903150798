

















































































import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomerPaymentInfoProperties } from "./CustomerPaymentInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CustomerPaymentProductService from "@/services/CustomerPaymentProductService";

@Component({
    data: () => ({
        products: [],
    }),
})
export default class CustomerPaymentProductView extends Vue {
    @Prop() private properties: CustomerPaymentInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private detail: any;
    private customerPaymentProductService = new CustomerPaymentProductService();

    public get currency() {
        return this.properties.currency;
    }

    public formatPrice(v: any) {
        const symbol = this.currency?.symbol ?? "";
        return symbol + " " + (v ?? 0).toFixed(2);
    }

    public formatTotalAmount(v: any) {
        const symbol = this.currency?.symbol ?? "";
        return symbol + " " + (v ?? 0).toFixed(2);
    }

    public created() {
        this.properties.events.subscribe("load-products", this.load);
        this.load();
    }

    public destroyed() {
        this.properties.events.remove("load-products", this.load);
    }

    public async load() {
        try {
            if (this.detail.id) {
                const r = await this.customerPaymentProductService.get({ 
                    customerPaymentDetailId: this.detail.id 
                });
                const products: any[] = r.data.customerPaymentProducts;
                this.$data.products = products.sort((lhs, rhs) => rhs.id - lhs.id);
            } else {
                this.$data.products = [];
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }
}
